






























import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import { technologies } from "@/mock/technologies";
import { projects } from "@/mock/projects";

import { RouterPathName } from "@/enums/router-path-name.enum";

@Component({
  components: {
    TechnologiesList: () =>
      import(
        /* webpackChunkName: 'app-footer-technologies-list' */ "@/components/technology/TechnologiesList.vue"
      ),
    ProjectsList: () =>
      import(
        /* webpackChunkName: 'app-footer-projects-list' */ "@/components/project/ProjectsList.vue"
      )
  }
})
export default class AppFooter extends Vue {
  /** Available technologies */
  technologies = technologies;

  /** Available projects */
  projects = projects;

  /** Router path names dictionary */
  routerPathName = RouterPathName;
}
